


import {
  Component,
  Vue,
  Prop,
} from 'nuxt-property-decorator';
import * as CSS from 'csstype';
// @ts-ignore
import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';

import type { SliderItem } from '~/types';
import TheSliderButton from '~/components/slider/TheSliderButton/TheSliderButton.vue';
import TheSliderTitle from '~/components/slider/TheSliderTitle/TheSliderTitle.vue';
import TheSliderItem from '~/components/slider/TheSliderItem/TheSliderItem.vue';
import getSlugFromUrl from '~/utils/getSlugFromUrl';

// отступ между элементами карусели
const HORIZONTAL_PADDINGS_ITEM = 12;
// ширина 1 элемента карусели + паддинги
const WIDTH_ITEM_MOBILE = 160 + HORIZONTAL_PADDINGS_ITEM;
const WIDTH_ITEM_DESKTOP = 160 + HORIZONTAL_PADDINGS_ITEM;
// количество элементов, которые скроллятся при клике на кнопку
const COUNT_ELEM_SCROLL = 3;
// продолжительность анимации в секундах при клике на кнопку
const TRANSITION_DURATION = 0.8;
// паддинги по бокам у .slider__list
const SLIDER_LIST_HORIZONTAL_PADDING = 24;
const SLIDER_LIST_HORIZONTAL_PADDING_MOBILE = 16;

@Component({
  components: {
    TheSliderButton,
    TheSliderTitle,
    TheSliderItem,
    IntersectionObserver,
  },
})
export default class TheSlider extends Vue {
  // https://github.com/vuejs/vue-class-component/issues/94
  $refs!: {
    sliderContainer: HTMLElement,
    sliderList: HTMLElement
  }

  @Prop({
    type: Array,
    required: true,
  }) readonly sliderList!: SliderItem[] | IArticle[]

  @Prop({
    default: null,
  }) readonly sliderTitle?: string

  isDisableScroll = false

  isShowButtonPrev = false
  isShowButtonNext = false
  isDisableButtonPrev = false
  isDisableButtonNext = false
  isFirstSendAnalytics = true;

  middle = 0
  sliderWidth = 0

  get itemWidth (): number {
    if (process.browser) {
      return (window.innerWidth >= 1280)
        ? WIDTH_ITEM_DESKTOP
        : WIDTH_ITEM_MOBILE;
    }
    return 0;
  }

  get sliderClasses (): object {
    return {
      'slider__available-scroll-prev': this.isShowButtonPrev,
      'slider__available-scroll-next': this.isShowButtonNext,
    };
  }

  get buttonPrevClasses (): object {
    return {
      slider__button_disabled: this.isDisableButtonPrev,
      slider__button_prev: true,
    };
  }

  get buttonNextClasses (): object {
    return {
      slider__button_disabled: this.isDisableButtonNext,
      slider__button_next: true,
    };
  }

  get sliderListStyle (): CSS.StandardProperties {
    if (process.browser) {
      const listPadding = (document.documentElement.clientWidth >= 768) ? SLIDER_LIST_HORIZONTAL_PADDING : SLIDER_LIST_HORIZONTAL_PADDING_MOBILE;
      return {
        // (сумма ширины всех элементов слайдера и паддинга элемента списка) - величина паддингов одного элемента
        // т.к. у первого и последнего элемента карусели нет по одному отступу
        width: `${((this.itemWidth * this.sliderList.length) + (listPadding * 2)) - HORIZONTAL_PADDINGS_ITEM}px`,
      };
    }
    return {};
  }

  handleMouseOut ({ target }: MouseEvent): void {
    // Иначе дергается кнопка при наведении мыши на слайдер через кнопку.
    // См. https://youtrack.lifehacker.ru/issue/LH-840
    const eventFromButton = target && (target as HTMLElement)?.closest('.slider__button');
    if (eventFromButton) {
      return;
    }

    this.isShowButtonNext = false;
    this.isShowButtonPrev = false;
  }

  handleMouseMove ({ clientX }: MouseEvent): void {
    if (clientX >= this.middle) {
      this.isShowButtonPrev = false;
      this.isShowButtonNext = true;
    } else {
      this.isShowButtonNext = false;
      this.isShowButtonPrev = true;
    }
  }

  handleSliderItemClick (link: string): void {
    // https://youtrack.lifehacker.ru/issue/LH-300
    // если главная
    this.$emit('onClickSlide', link);
    if (this.$route.name === 'index') {
      this.$sendAnalyticsEvent({
        event: 'Выбор материала_главная',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: getSlugFromUrl(link),
        element: 'карусель',
        item: 'виджет',
        action: '',
        value: 1,
        currency: 'piece',
      });
    }
  }

  calculateMiddle (): void {
    const { sliderContainer } = this.$refs;
    if (!sliderContainer) {
      return;
    }

    const box = sliderContainer.getBoundingClientRect();
    this.middle = (box.left + ((box.right - box.left) / 2));
  }

  updateButtonState (): void {
    const { sliderContainer, sliderList } = this.$refs;
    if (!sliderContainer || !sliderList) {
      return;
    }

    const { scrollLeft } = sliderContainer;

    if (scrollLeft >= parseInt(sliderList.style.width, 10) - this.sliderWidth - 1) {
      this.isDisableButtonNext = true;
      this.isDisableButtonPrev = false;
    } else if (scrollLeft <= 0) {
      this.isDisableButtonPrev = true;
      this.isDisableButtonNext = false;
    } else {
      this.isDisableButtonNext = false;
      this.isDisableButtonPrev = false;
    }
  }

  handleButtonClick (isNext: boolean = false): void {
    if (this.isDisableScroll) {
      this.sendScrollAnalytics(false);
      return;
    }

    const { sliderContainer, sliderList } = this.$refs;
    if (!sliderContainer || !sliderList) {
      this.sendScrollAnalytics(false);
      return;
    }

    const { scrollLeft } = sliderContainer;
    const sliderListWidth = parseInt(sliderList.style.width, 10);
    if (isNext && (scrollLeft >= (sliderListWidth - this.sliderWidth))) {
      this.sendScrollAnalytics(false);
      return;
    }
    if (!isNext && (scrollLeft <= 0)) {
      this.sendScrollAnalytics(false);
      return;
    }

    this.scroll(isNext);
  }

  sendScrollAnalytics (isSuccessful: boolean) {
    if (this.isFirstSendAnalytics) {
      this.$sendAnalyticsSnowPlow({
        event_name: 'Скролл_Лучшие предложения',
        par3: String(this.sliderTitle),
      });

      this.$sendYandexMetrika({
        level1: 'Скролл_Лучшие предложения',
        level4: isSuccessful ? 1 : 0,
      });

      this.isFirstSendAnalytics = false;
    }
  }

  sendSuccessfulScrollAnalytics () {
    this.sendScrollAnalytics(true);
  }

  scroll (isNext: boolean): void {
    this.isDisableScroll = true;

    const scroll: number = this.itemWidth * COUNT_ELEM_SCROLL;
    let afterScroll: number,
      maxScroll: number,
      isFullScroll: boolean,
      operator: string;

    if (isNext) {
      afterScroll = this.$refs.sliderContainer.scrollLeft + scroll;
      maxScroll = parseInt(this.$refs.sliderList.style.width, 10) - this.sliderWidth;
      isFullScroll = !(afterScroll > maxScroll);
      operator = '-';
    } else {
      afterScroll = this.$refs.sliderContainer.scrollLeft - scroll;
      maxScroll = 0;
      isFullScroll = !(afterScroll < maxScroll);
      operator = '+';
    }

    // если доступен полный скролл равный itemWidth * COUNT_ELEM_SCROLL
    if (isFullScroll) {
      this.$refs.sliderList.style.transitionDuration = `${TRANSITION_DURATION}s`;

      // Анимация выполняется с помощью translate3d, а не постепенным изменением свойства scrollleft
      // из-за дерганий в сафари на странице с большим количеством изображений
      this.$refs.sliderList.style.transform = `translate3d(${operator}${scroll}px, 0px, 0px)`;

      setTimeout(() => {
        this.setScrollLeft(isNext, scroll);
      }, TRANSITION_DURATION * 1000);
    } else {
      // доступная величина скролла
      let scrollComputed: number;

      if (isNext) {
        scrollComputed = (scroll - (afterScroll - maxScroll));
      } else {
        scrollComputed = (scroll - (-afterScroll));
      }
      const transitionDurationComputed: number = Number(((scrollComputed / scroll) * TRANSITION_DURATION).toFixed(1));

      this.$refs.sliderList.style.transitionDuration = `${transitionDurationComputed}s`;
      this.$refs.sliderList.style.transform = `translate3d(${operator}${scrollComputed}px, 0px, 0px)`;

      setTimeout(() => {
        this.setScrollLeft(isNext, scrollComputed);
      }, transitionDurationComputed * 1000);
    }
  }

  setScrollLeft (isNext: boolean, scrollValue: number): void {
    // после окончания анимации убираем стили анимации и устанавливаем свойство scrollLeft
    // Это нужно для того чтобы сохранить возможность скроллить карусель с трекпада и мыши
    this.$refs.sliderList.style.transitionDuration = '0s';
    this.$refs.sliderList.style.transform = 'translate3d(0,0,0)';

    if (isNext) {
      this.$refs.sliderContainer.scrollLeft += scrollValue;
    } else {
      this.$refs.sliderContainer.scrollLeft -= scrollValue;
    }

    this.isDisableScroll = false;
  }

  setSliderWidth (): void {
    this.sliderWidth = parseInt(getComputedStyle(this.$refs.sliderContainer).width, 10);
  }

  isTrackTheAppearance (article: IArticle | SliderItem): boolean {
    if ('testPixel' in article) {
      return !!article.testPixel?.length;
    }
    return false;
  }

  onAppear (article: IArticle | SliderItem): void {
    this.$emit('appear-item', article);
  }

  mounted () {
    this.setSliderWidth();

    this.calculateMiddle();

    this.updateButtonState();

    this.$refs.sliderContainer.addEventListener('scrollend', this.sendSuccessfulScrollAnalytics);
  }

  beforeDestroy () {
    this.$refs.sliderContainer.removeEventListener('scrollend', this.sendSuccessfulScrollAnalytics);
  }
}
