import type {
  NodeEnv,
  UrlExample,
  RedisUrlExample,
  HostExample,
  SentryUrlExample,
} from '@devhacker/shared/utils/validateEnv';
import { validateEnv, StringList } from '@devhacker/shared/utils/validateEnv';

type GaId = 'G-XXXXXXXXXX';
type GtmId = 'GTM-XXXXXXX';
type TopMalId = 'XXXXXXX';

export const NODE_ENV = validateEnv<NodeEnv>({
  name: 'NODE_ENV',
  value: process.env.NODE_ENV,
  type: String,
  fallback: 'development',
});

export const isProduction = NODE_ENV === 'production';
export const isDevelopment = NODE_ENV === 'development';
export const PROD_ORIGIN = 'https://lifehacker.ru';

export const NUXT_PORT = validateEnv({
  name: 'NUXT_PORT',
  value: process.env.NUXT_PORT,
  type: Number,
  fallback: 5000,
});

export const SENTRY_DSN = validateEnv<SentryUrlExample>({
  name: 'SENTRY_DSN',
  value: process.env.SENTRY_DSN,
  type: String,
  fallback: 'https://56b278f0b7704479828cdeff80aa700a@sentry.lifehacker.ru/2' as SentryUrlExample,
});

export const SENTRY_ENABLED = validateEnv({
  name: 'SENTRY_ENABLED',
  value: process.env.SENTRY_ENABLED,
  type: Boolean,
});

export const SOURCE_MAP_ENABLED = validateEnv({
  name: 'SOURCE_MAP_ENABLED',
  value: process.env.SOURCE_MAP_ENABLED,
  type: Boolean,
});

export const REDIS_URL = validateEnv<RedisUrlExample>({
  name: 'REDIS_URL',
  value: process.env.REDIS_URL,
  type: String,
});

export const REDIS_APP_CACHE = validateEnv({
  name: 'REDIS_APP_CACHE',
  value: process.env.REDIS_APP_CACHE,
  type: Boolean,
});

export const REDIS_SSR_CACHE = validateEnv({
  name: 'REDIS_SSR_CACHE',
  value: process.env.REDIS_SSR_CACHE,
  type: Boolean,
});

export const REDIS_SSR_CACHE_HTTP_TOKEN = validateEnv({
  name: 'REDIS_SSR_CACHE_HTTP_TOKEN',
  value: process.env.REDIS_SSR_CACHE_HTTP_TOKEN,
  type: String,
});

export const WORDPRESS_API_URL = validateEnv<UrlExample>({
  name: 'WORDPRESS_API_URL',
  value: process.env.WORDPRESS_API_URL,
  type: String,
});

export const HOME_URL = validateEnv<UrlExample>({
  name: 'HOME_URL',
  value: process.env.HOME_URL,
  type: String,
});

export const TALKER_API_URL = validateEnv<UrlExample>({
  name: 'TALKER_API_URL',
  value: process.env.TALKER_API_URL,
  type: String,
});

export const QUIZ_API_URL = validateEnv<UrlExample>({
  name: 'QUIZ_API_URL',
  value: process.env.QUIZ_API_URL,
  type: String,
});

export const AUTH_API_URL = validateEnv<UrlExample>({
  name: 'AUTH_API_URL',
  value: process.env.AUTH_API_URL,
  type: String,
});

export const PUSHER_API_URL = validateEnv<UrlExample>({
  name: 'PUSHER_API_URL',
  value: process.env.PUSHER_API_URL,
  type: String,
});

export const PUSH_API_URL = validateEnv<UrlExample>({
  name: 'PUSH_API_URL',
  value: process.env.PUSH_API_URL,
  type: String,
});

export const FULLSEARCH_API_URL = validateEnv<UrlExample>({
  name: 'FULLSEARCH_API_URL',
  value: process.env.FULLSEARCH_API_URL,
  type: String,
});

export const SPECIAL_API_URL = validateEnv<UrlExample>({
  name: 'SPECIAL_API_URL',
  value: process.env.SPECIAL_API_URL,
  type: String,
});

export const API_GATEWAY = validateEnv<UrlExample>({
  name: 'API_GATEWAY',
  value: process.env.API_GATEWAY,
  type: String,
});

export const API_GATEWAY_ENABLED = validateEnv({
  name: 'API_GATEWAY_ENABLED',
  value: process.env.API_GATEWAY_ENABLED,
  type: Boolean,
});

export const REACTIONS_API_URL = validateEnv<UrlExample>({
  name: 'REACTIONS_API_URL',
  value: process.env.REACTIONS_API_URL,
  type: String,
});

export const SAFARI_PUSH_WEB_SERVICE_URL = validateEnv<UrlExample>({
  name: 'SAFARI_PUSH_WEB_SERVICE_URL',
  value: process.env.SAFARI_PUSH_WEB_SERVICE_URL,
  type: String,
});

export const SAFARI_PUSH_ID = validateEnv<'xxx.xxx.lifehacker.push'>({
  name: 'SAFARI_PUSH_ID',
  value: process.env.SAFARI_PUSH_ID,
  type: String,
});

export const SAFARI_COOKIE_NAME = validateEnv({
  name: 'SAFARI_COOKIE_NAME',
  value: process.env.SAFARI_COOKIE_NAME,
  type: String,
});

export const ACCESS_TOKEN_COOKIE_NAME = validateEnv({
  name: 'ACCESS_TOKEN_COOKIE_NAME',
  value: process.env.ACCESS_TOKEN_COOKIE_NAME,
  type: String,
});

export const ACCESS_TOKEN_COOKIE_DOMAIN = validateEnv<HostExample>({
  name: 'ACCESS_TOKEN_COOKIE_DOMAIN',
  value: process.env.ACCESS_TOKEN_COOKIE_DOMAIN,
  type: String,
});

export const GA_ID = validateEnv<GaId>({
  name: 'GA_ID',
  value: process.env.GA_ID,
  type: String,
});

export const GA_ID_RECIPE = validateEnv<GaId>({
  name: 'GA_ID_RECIPE',
  value: process.env.GA_ID_RECIPE,
  type: String,
});

export const GTM_ID_AMP = validateEnv<GtmId>({
  name: 'GTM_ID_AMP',
  value: process.env.GTM_ID_AMP,
  type: String,
});

export const GTM_IDS = validateEnv<GtmId[]>({
  name: 'GTM_IDS',
  value: process.env.GTM_IDS,
  type: StringList,
});

export const GTM_IDS_IN_RECIPES = validateEnv<GtmId[]>({
  name: 'GTM_IDS_IN_RECIPES',
  value: process.env.GTM_IDS_IN_RECIPES,
  type: StringList,
});

export const YA_METRIKA_ID = validateEnv({
  name: 'YA_METRIKA_ID',
  value: process.env.YA_METRIKA_ID,
  type: Number,
});

export const YA_METRIKA_ID2 = validateEnv({
  name: 'YA_METRIKA_ID2',
  value: process.env.YA_METRIKA_ID2,
  type: Number,
});

export const YA_METRIKA_ID_RECIPE = validateEnv({
  name: 'YA_METRIKA_ID_RECIPE',
  value: process.env.YA_METRIKA_ID_RECIPE,
  type: Number,
});

export const YA_METRIKA_ID_HEALTH = validateEnv({
  name: 'YA_METRIKA_ID_HEALTH',
  value: process.env.YA_METRIKA_ID_HEALTH,
  type: Number,
});

export const YA_METRIKA_IDS_AMP = validateEnv<string[]>({
  name: 'YA_METRIKA_IDS_AMP',
  value: process.env.YA_METRIKA_IDS_AMP,
  type: StringList,
});

export const YA_METRIKA_IDS_AMP_IN_RECIPES = validateEnv<string[]>({
  name: 'YA_METRIKA_IDS_AMP_IN_RECIPES',
  value: process.env.YA_METRIKA_IDS_AMP_IN_RECIPES,
  type: StringList,
});

export const TOP_MAIL_ID = validateEnv<TopMalId>({
  name: 'TOP_MAIL_ID',
  value: process.env.TOP_MAIL_ID,
  type: String,
});

export const SPORTS_RU_ENVIRONMENT = validateEnv<NodeEnv>({
  name: 'SPORTS_RU_ENVIRONMENT',
  value: process.env.SPORTS_RU_ENVIRONMENT,
  type: String,
});

export const PUSH_VAPID_PUBLIC_KEY = validateEnv<'xXx-xXXxx_xx_Xxx_Xx_Xx-xxXx'>({
  name: 'PUSH_VAPID_PUBLIC_KEY',
  value: process.env.PUSH_VAPID_PUBLIC_KEY,
  type: String,
});

export const VIEWS_API_URL = validateEnv<UrlExample>({
  name: 'VIEWS_API_URL',
  value: process.env.VIEWS_API_URL,
  type: String,
});

export const STRIP_WIDGET_PROPERTIES = validateEnv({
  name: 'STRIP_WIDGET_PROPERTIES',
  value: process.env.STRIP_WIDGET_PROPERTIES,
  type: Boolean,
  fallback: false,
});

export const ENVRIONMENT_PREFIX = validateEnv({
  name: 'ENVRIONMENT_PREFIX',
  value: process.env.ENVRIONMENT_PREFIX,
  type: String,
  fallback: '',
});
